var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[(!_vm.toggle)?_c('h6',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm._f("month")(_vm.topic.recordedAt))+" "),(!_vm.isApproved)?_c('span',[(!_vm.topic.approvedAt)?_c('fa',{staticClass:"text-warning ml-2 pointer",attrs:{"icon":"pencil-alt"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}}):_vm._e(),_c('fa',{staticClass:"mx-2 pointer",class:{
            'text-danger': _vm.topic.approvedAt,
            'text-secondary': !_vm.topic.approvedAt,
          },attrs:{"icon":"lock"},on:{"click":_vm.toggleLock}})],1):_vm._e(),_c('span',{staticClass:"float-right"},[_c('span',[_vm._v("เป้าหมาย: "+_vm._s(_vm._f("comma")(_vm.topic.targetValue,-1)))]),_c('span',{staticClass:"ml-3"},[_vm._v("ค่าจริง: "+_vm._s(_vm._f("comma")(_vm.topic.actualValue,-1)))])])]):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"card-header"},[_c('h6',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm._f("month")(_vm.topic.recordedAt))+" "),_c('fa',{staticClass:"text-warning ml-1 pointer",attrs:{"icon":"pencil-alt"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-month',{staticClass:"col-12",attrs:{"label":"เดือน","validations":[
            {text: 'required!', value: !_vm.$v.formData.recordedAt.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.recordedAt),callback:function ($$v) {_vm.$set(_vm.formData, "recordedAt", $$v)},expression:"formData.recordedAt"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"เป้าหมาย","precision":2,"validations":[
            {text: 'required!', value: !_vm.$v.formData.targetValue.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.targetValue),callback:function ($$v) {_vm.$set(_vm.formData, "targetValue", $$v)},expression:"formData.targetValue"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"ค่าจริง","precision":2,"validations":[
            {text: 'required!', value: !_vm.$v.formData.actualValue.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.actualValue),callback:function ($$v) {_vm.$set(_vm.formData, "actualValue", $$v)},expression:"formData.actualValue"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"label":"ผ่านเกณฑ์","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)]),_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateTopic(_vm.topic.id)}}},[_vm._v(" แก้ไข ")]),_c('button',{staticClass:"btn btn-danger float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyTopic(_vm.topic.id)}}},[_vm._v(" ลบ ")])]):_vm._e(),_vm._l((_vm.topic.details),function(detail){return _c('DetailTopicDetail',{key:detail.id,staticClass:"card-body py-0",attrs:{"detail":detail,"canEdit":_vm.toggle,"paperType":_vm.paperType,"templateType":_vm.templateType},on:{"updated":_vm.emitUpdated}})}),(_vm.toggle)?_c('button',{staticClass:"btn btn-outline-success mx-3 my-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.createDetail(_vm.topic.id)}}},[_vm._v(" เพิ่มรายละเอียด ")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }