<template>
  <div class="row">
    <div
      class="col-12"
      :class="{'col-sm-6': topic.approvedAt, 'col-sm-8': !topic.approvedAt}">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          :value="value"
          @input="$emit('input', $event.target.value)">
        <div class="input-group-append">
          <span class="input-group-text text-light">ปี ค.ศ.</span>
          <span
            v-if="!topic.approvedAt"
            class="input-group-text text-success pointer"
            @click="createTopic">
            เพิ่มรายการ
          </span>
          <span
            v-if="!topic.approvedAt"
            class="input-group-text text-warning pointer"
            @click="toggle = !toggle">
            แก้ไขสรุปผล
          </span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-row">
        <sgv-input-text
          :disabled="!toggle"
          class="col-6"
          label="เป้าหมาย"
          placeholder="Ex. >=10, <5 เป็นต้น"
          v-model="formData.target">
        </sgv-input-text>

        <sgv-input-number
          :disabled="!toggle"
          class="col-6"
          label="ค่าจริง"
          minus
          :precision="2"
          v-model="formData.actualValue">
        </sgv-input-number>

        <sgv-input-textarea
          :disabled="!toggle"
          class="col-12 col-md-6"
          label="การคำนวณ"
          auto-grow
          v-model="formData.calculation">
        </sgv-input-textarea>

        <sgv-input-textarea
          :disabled="!toggle"
          class="col-12 col-md-6"
          label="สรุปผล"
          auto-grow
          v-model="formData.summary">
        </sgv-input-textarea>
      </div>

      <button
        v-if="toggle"
        type="button"
        class="btn btn-warning form-group"
        @click="updateData">
        แก้ไข
      </button>

      <div class="form-row">
        <DetailFormUser
          class="col-6"
          title="จัดทำ/ทบทวน"
          :value="getUser('reviewed')"
          :isSubmit="!topic.approvedAt && !topic.reviewedAt && $auth.hasRole(`paper:${paperType}:topic:review`)"
          :isUndo="!topic.approvedAt && topic.reviewedAt && $auth.hasRole(`paper:${paperType}:topic:review`)"
          :submitFunc="reviewSubmit"
          :undoFunc="reviewUndo"
          @updated="emitUpdate">
        </DetailFormUser>

        <DetailFormUser
          class="col-6"
          title="อนุมัติ"
          :value="getUser('approved')"
          :isSubmit="topic.reviewedAt && !topic.approvedAt && $auth.hasRole(`paper:${paperType}:topic:approve`)"
          :isUndo="topic.reviewedAt && topic.approvedAt && $auth.hasRole(`paper:${paperType}:topic:approve`)"
          :submitFunc="approveSubmit"
          :undoFunc="approveUndo"
          @updated="emitUpdate">
        </DetailFormUser>
      </div>
    </div>
  </div>
</template>

<script>
import DetailFormUser from './DetailFormUser'
import {
  REVIEW_SUBMIT_PAPER_TOPIC,
  REVIEW_UNDO_PAPER_TOPIC,
  APPROVE_SUBMIT_PAPER_TOPIC,
  APPROVE_UNDO_PAPER_TOPIC,
  CREATE_TOPIC,
  UPDATE_TOPIC_APPROVE
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    topic: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        target: '',
        calculation: '',
        summary: '',
        actualValue: 0
      },
      actions: []
    }
  },
  methods: {
    emitUpdate () {
      this.$emit('updated', null)
    },
    createTopic () {
      const targetValue = this.formData.target.replace(/(<|>|=|%)/g, '').trim()

      if (isNaN(targetValue)) {
        this.$toasted.global.error('กำหนดค่าเป้าหมายไม่ถูกต้อง')
        return
      }

      this.$apollo.mutate({
        mutation: CREATE_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          input: {
            recordedAt: new Date(),
            targetValue: +targetValue,
            actualValue: 0,
            yearAt: +this.value
          }
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$apollo.mutate({
        mutation: UPDATE_TOPIC_APPROVE(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
          input: this.formData
        }
      })
      .then(() => {
        this.toggle = false
        this.$emit('updated', null)
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    getUser (type) {
      const date = this.topic[type + 'At']
      const user = this.topic[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    reviewSubmit (date) {
      return this.$apollo.mutate({
        mutation: REVIEW_SUBMIT_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
          submitDate: date
        }
      })
    },
    reviewUndo () {
      return this.$apollo.mutate({
        mutation: REVIEW_UNDO_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
        }
      })
    },
    approveSubmit (date) {
      return this.$apollo.mutate({
        mutation: APPROVE_SUBMIT_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
          submitDate: date
        }
      })
    },
    approveUndo () {
      return this.$apollo.mutate({
        mutation: APPROVE_UNDO_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
        }
      })
    },
  },
  watch: {
    topic: {
      handler (value) {
        this.formData.target = value.target
        this.formData.calculation = value.calculation
        this.formData.summary = value.summary
        this.formData.actualValue = value.actualValue
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    DetailFormUser
  }
}
</script>

<style lang="css" scoped>
</style>
