<template>
  <div class="row">
    <div class="col-12 mb-3">
      <canvas id="targetGraphChart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      months: [
        'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
        'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
        'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
      ],
      myChart: null
    }
  },
  computed: {
    mapItems () {
      return this.items.map(v => {
        const d = new Date(v.recordedAt)
        const month = d.getMonth()
        return {
          month,
          target: v.targetValue,
          actual: v.actualValue,
          isActive: v.isActive
        }
      })
    },
    maxValue () {
      return this.items.reduce((t,v) => {
        return Math.max(t, Math.max(v.targetValue, v.actualValue))
      }, 0)
    },
    mapDatasets () {
      return [{
        label: 'Actual',
        minBarLength: 0,
        data: this.months.map((v,i) => {
          const found = this.mapItems.find(item => item.month == i)
          if (found) return found.actual
          else return 0
        }),
        backgroundColor: this.months.map((v,i) => {
          const found = this.mapItems.find(item => item.month == i)
          if (found && found.isActive) {
            return 'rgba(27,201,142,0.4)'
          } else {
            return 'rgba(230,71,89,0.5)'
          }
        }),
      }]
    },
  },
  methods: {
    createChart () {
      if (this.myChart) {
        this.myChart.destroy()
      }

      const ctx = document.getElementById('targetGraphChart')
      this.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: this.mapDatasets
        },
        options: {
          legend: {
            display: false
          },
          responsive: true,
          lineTension: 1,
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                padding: 25,
                suggestedMax: this.maxValue
              }
            }
          }
        },
      })
    },
  },
  watch: {
    mapDatasets: {
      handler: 'createChart'
    }
  },
  mounted () {
    this.createChart()
  }
}
</script>

<style lang="css" scoped>
</style>
